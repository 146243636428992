import { closeSidebar, fetchMainMenu } from "@/core/layouts";

import WS from '@/core/wsclient';
import router from "@/router";

import { loadSchema } from "@/core/db";

import { toastSuccess } from '@/core/helpers/toastify';

import { schema } from '@/api/db';
import { logout, proceedToken, status } from '@/api/auth';
import { constants, brigade } from '@/api/system';
import { messageDelete, messageRead } from '@/api/messages';

export default {
	async login({ commit }: { commit: any }, { token, user }: { token: any, user: any }) {
		if (user.exp) {
			const date = new Date;

			user.exp = date.getTime() + user.exp * 1000;
		}

		commit('token', token);
		commit('timeout', 0);
		commit('user', user);

		commit('loaded', false);

		await fetchMainMenu('mobilemenu');

		commit('constants', await constants());

		commit('brigade', await brigade());

		loadSchema(await schema());

		commit('loaded', true);

		router.push("/");

		WS.send({ event: 'login', data: token });

		closeSidebar();
	},

	async status({ commit, state }: { commit: any, state: any }) {
		const response: any = await status();
		commit('loaded', false);

		if (response.exp) {
			const date = new Date;

			response.exp = date.getTime() + response.exp * 1000;
		}

		if (!response.id && state.token) commit('token', null);

		commit('user', response);

		await fetchMainMenu('mobilemenu');

		commit('constants', await constants());

		commit('brigade', await brigade());

		loadSchema(await schema());

		commit('loaded', true);

		WS.send({ event: 'login', data: state.token });

		return response;
	},

	async logout({ commit }: { commit: any }) {
		const response: any = await logout();

		commit('loaded', false);
		commit('token', null);
		commit('timeout', 0);

		await router.push("/");

		await fetchMainMenu('mobilemenu');

		const resSchema: any = await schema();
		loadSchema(resSchema);
		commit('loaded', true);

		commit('user', response);

		WS.send({ event: 'logout' });
		closeSidebar();
	},

	async proceedToken({ commit, state }: { commit: any, state: any }) {
		const response: any = await proceedToken();
		if (response.token) {
			if (response.exp) {
				const date = new Date;

				response.exp = date.getTime() + response.exp * 1000;
			}

			commit('timeout', 0);
			commit('token', response.token);
			commit('user', Object.assign(state.user, { exp: response.exp }));
		}
	},

	async messagesRead({ commit }: { commit: any }) {
		const response: any = await messageRead();

		commit('messages', response);
	},

	async messagesDelete({ state }: { state: any }, id: any) {
		for (const i in state.messages) {
			if (state.messages[i].id == id) {
				await messageDelete(id);

				state.messages.splice(i, 1);

				break;
			}
		}
	},

	newMessage({ state }: { state: any }, message: any) {
		toastSuccess(message.message);

		state.messages.unshift(message);
	},

	online({ commit }: { commit: any }, value: any) {
		commit('online', value);
	},

	mute({ commit, state }: { commit: any, state: any }) {
		commit('mute', !state.mute);
	}
}
